import axios from 'axios';

axios.defaults.headers.common['X-XSRF-TOKEN'] = document.cookie.split('=')[1];

axios.defaults.baseURL = process.env.VUE_APP_BACKEND_URL;

export default {
    loginUser(form) {
        return axios.post(`/security/loginUser`, form);
    },
    verifyTwoFactor(object) {
        return axios.post(`/Security/verifyTwoFactor`, object);
    },
    // ColoredExcelFile(excelFile)
    ColoredExcelFile(excelFile) {
        return axios.get(`/Api/Admin/Endorsements/ColoredExcelFile?excelFile=${excelFile}`);
    },
    // UploadExcel 
    UploadExcel(data) {
        return axios.post('/Api/Admin/Endorsements/UploadExcel', data);
    },
    //get request query string PrintCandidateEndorsmentsReports string FullName , string NID, string constituency, string subconstituency, int total , int voter , int notvoter, int notinsame, int empty , int duplicate
    GetEndorsementsReports(FullName, NID, constituency, subconstituency, total, voter, notvoter, notinsame, empty, blocked, duplicate, cnstype, cardDistributed, cardNotDistibuted) {
        return axios.get('/Api/Admin/Endorsements/PrintCandidateEndorsmentsReports?FullName=' + FullName + '&NID=' + NID + '&constituency=' + constituency + '&subconstituency=' + subconstituency + '&total=' + total + '&voter=' + voter + '&notvoter=' + notvoter + '&notinsame=' + notinsame + '&empty=' + empty + '&blocked=' + blocked + '&duplicate=' + duplicate + '&cnstype=' + cnstype + '&cardDistributed=' + cardDistributed + '&cardNotDistibuted=' + cardNotDistibuted);
    },
    // PrintCandidateReportList 
    PrintCandidateReportList(CandidateType) {
        return axios.get(`/Api/Admin/Endorsements/PrintCandidateReportList?CandidateType=${CandidateType}`);
    },
    // PrintCandidateList
    PrintCandidateList(CandidateType, nid) {
        return axios.get(`/Api/Admin/Endorsements/PrintCandidateList?CandidateType=${CandidateType}&nid=${nid}`);
    },
    // PrintSenateCandidatesList
    PrintSenateCandidatesList(CandidateType, nid) {
        return axios.get(`/Api/Admin/Endorsements/PrintSenateCandidatesList?CandidateType=${CandidateType}&nid=${nid}`);
    },
    GetCandidateEndorsementChecks(pageNo, pageSize, consitituency, subconstituency, candidateType, DateFrom, DateTo) {
        return axios.get('/Api/Admin/Endorsements/CandidateEndorsementChecks?pageNo=' + pageNo + '&pageSize=' + pageSize + '&consitituency=' + consitituency + '&subconstituency=' + subconstituency + '&candidateType=' + candidateType + '&DateFrom=' + DateFrom + '&DateTo=' + DateTo);
    },
    // confirmNationalId(nationalId) {
    //     return axios.get(`/Api/Admin/Endorsements/ConfirmNationalId/${nationalId}`);
    // },
    ConfirmCandidateNationalId(nationalId) {
        return axios.get(`/Api/Admin/Endorsements/ConfirmCandidateNationalId/${nationalId}`);
    },

    ConfirmPresidentalCandidateNationalId(nationalId) {
        return axios.get(`/Api/Admin/Endorsements/ConfirmPresidentalCandidateNationalId/${nationalId}`);
    },

    ConfirmSenateCandidateNationalId(nationalId) {
        return axios.get(`/Api/Admin/Endorsements/ConfirmSenateCandidateNationalId/${nationalId}`);
    },
    GetEndorsementsInfo(pcnsId, pageNo, pageSize) {
        return axios.get(`/Api/Admin/Endorsements/Get?pageno=${pageNo}&pagesize=${pageSize}&pcnsId=${pcnsId}`);
    },
    AddEndorsments(Endorsments) {
        return axios.post(`/Api/Admin/Endorsements/Add`, Endorsments);
    },
    IsLogin() {
        return axios.get(`/Security/IsLogin`);
    },
    GetProfiles(pageNo, pageSize) {
        return axios.get(`/Api/Admin/Profile/Get?pageno=${pageNo}&pagesize=${pageSize}`);
    },
    GetAllProfiles() {
        return axios.get(`/Api/Admin/Profile/GetAllProfiles`);
    },
    GetAllEntities() {
        return axios.get(`/api/Admin/Entities/GetAll`);
    },
    GetRuningProfile() {
        return axios.get(`/Api/Admin/Profile/GetRuningProfile`);
    },
    GetActiveProfile() {
        return axios.get(`/Api/Admin/Profile/GetActiveProfile`);
    },
    GetConstituenciesBasedOn() {
        return axios.get(`/Api/Admin/Constituencies/GetAll`);
    },
    GetSenateConstituenciesBasedOn() {
        return axios.get(`/Api/Admin/SenateConstituencies/GetAll`);
    },
    AddProfiles(Profile) {
        return axios.post(`/Api/Admin/Profile/Add`, Profile);
    },
    ActivateProfile(ProfileId) {
        return axios.post(`/Api/Admin/Profile/${ProfileId}/ActivateProfile`);
    },
    DeActivateProfile(ProfileId) {
        return axios.post(`/Api/Admin/Profile/${ProfileId}/DeActivateProfile`);
    },
    PlayProfile(ProfileId) {
        return axios.post(`/Api/Admin/Profile/${ProfileId}/PlayProfile`);
    },
    PauseProfile(ProfileId) {
        return axios.post(`/Api/Admin/Profile/${ProfileId}/PauseProfile`);
    },
    // ************************ Entities **************************

    SecondReport(entityId) {
        return axios.get(`/api/Admin/Entities/SecondReport/${entityId}`, {
            responseType: "blob",
        });

    },
    GetEntities(pageNo, pageSize) {

        return axios.get(`/Api/Admin/Entities/Get?pageno=${pageNo}&pagesize=${pageSize}`);
    },
    GetEntityData(entityId) {
        return axios.get(`/Api/Admin/Entities/Get/${entityId}`);
    },
    GetEntityCandidate(entityId) {

        return axios.get(`/Api/Admin/Entities/Get/Candidate/${entityId}`);
    },
    AddEnitity(Entity) {
        return axios.post('/Api/Admin/Entities/Add', Entity);
    },
    confirmEnitity(EntityId) {
        return axios.get(`/Api/Admin/Entities/Confirm/${EntityId}`);
    },
    RejectEntity(EntityId) {
        return axios.get(`/Api/Admin/Entities/Reject/${EntityId}`);
    },
    EditEntity(item) {
        return axios.post('/Api/Admin/Entities/Edit', item);
    },

    SearchEntity(pageNo, pageSize, number, name, email, HasSupporterCode) {
        if (HasSupporterCode === null || HasSupporterCode === undefined || HasSupporterCode === '' || HasSupporterCode === 'null' || HasSupporterCode === 'undefined') {
            // set hasSupporterCode to nothing
            HasSupporterCode = '';
        }
        return axios.get(`/Api/Admin/Entities/SearchEntity?pageNo=${pageNo}&pageSize=${pageSize}&number=${number}&name=${name}&email=${email}&HasSupporterCode=${HasSupporterCode}`);
    },
    // PrintDailyMunicipalityReport /api/admin/Candidates/DailyMunicipalityReport
    PrintDailyMunicipalityReport(reportDate) {
        // Only include reportDate if it's not null
        const params = reportDate ? { reportDate: reportDate } : {};

        return axios.get(`/api/admin/Candidates/DailyMunicipalityReport`, {
            params: params,  // Send reportDate only if it's valid
            responseType: "blob",  // Expecting a blob response (PDF)
        })
            .then((response) => {
                // Create a blob from the response
                const blob = new Blob([response.data], { type: 'application/pdf' });
                return blob; // Return the blob for further handling
            })
            .catch((err) => {
                console.error('Error downloading the PDF:', err);
                throw err;
            });
    },

    //  this.$http.PrintGeneralAttorneyReport({ fromDate, toDate, reportName })
    PrintGeneralAttorneyReport(fromDate, toDate) {
        // Prepare the payload directly without any nesting
        const payload = {
            fromDate: fromDate || null,
            toDate: toDate || null,
        };

        return axios.post(`/api/admin/Candidates/GeneralAttorneyReport`, payload, {
            responseType: "blob", // Expecting a blob response (PDF)
        })
            .then((response) => {
                // Create a blob from the response
                const blob = new Blob([response.data], { type: 'application/pdf' });
                return blob; // Return the blob for further handling
            })
            .catch((err) => {
                console.error('Error downloading the PDF:', err);
                throw err;
            });
    },

    AdoptionEntityReport(entityId) {
        return axios.get(`/Api/Admin/Entities/AdoptionEntityReport?entityId=${entityId}`);
    },

    SearchEntitysInitialLists(pageNo, pageSize, constituencyId, SubConstituencyId, isPrint) {
        return axios.get(`/Api/Admin/Entities/SearchEntitysInitialLists?pageNo=${pageNo}&pageSize=${pageSize}&constituencyId=${constituencyId}&SubConstituencyId=${SubConstituencyId}&isPrint=${isPrint}`);
    },
    // ************************ Representatives **************************
    GetRepresentativesByEntityId(id) {
        return axios.get(`/api/Admin/Representatives/GetRepresentativesByEntityId/${id}`);
    },
    GetEntityRepresentativesByEntityId(id) {
        return axios.get(`/api/Admin/Representatives/GetEntityRepresentativesByEntityId/${id}`);
    },

    AddRepresentatives(Representatives) {
        return axios.post('/Api/Admin/Representatives/Add', Representatives);
    },
    // ************************ User Info **************************
    CheckLoginStatus() {
        return axios.post('/security/checkloginstatus');
    },

    getUser(pageNo, pageSize) {
        return axios.get(`/Api/Admin/User/getUser?pageNo=${pageNo}&pagesize=${pageSize}`);
    },

    AddUser(User) {
        return axios.post('/Api/Admin/User/AddUser', User);
    },

    EditUser(User) {
        return axios.post('/Api/Admin/User/EditUser', User);
    },

    DeactivateUser(UserId) {
        return axios.post(`/Api/Admin/User/${UserId}/Deactivate`);
    },

    ActivateUser(UserId) {
        return axios.post(`/Api/Admin/User/${UserId}/Activate`);
    },

    delteUser(UserId) {
        return axios.post(`/Api/Admin/User/${UserId}/delteUser`);
    },
    ResetTwoFA(UserId) {
        return axios.post(`Security/${UserId}/ResetTwoFA`);
    },
    UploadImage(obj) {
        return axios.post('/Api/Admin/User/UploadImage', obj);
    },

    EditUsersProfile(User) {
        return axios.post('/Api/Admin/User/EditUsersProfile', User);
    },

    ChangePassword(userPassword) {
        return axios.post(`/Security/ChangePassword`, userPassword);
    },
    // ************************ Constituencies **************************

    GetConstituencies() {
        return axios.get(`/Api/Admin/Constituencies/Get`);
    },
    AddConstituency(constituency) {
        return axios.post(`/api/Admin/Constituencies/Add`, constituency);
    },
    AddSenateConstituency(constituency) {
        return axios.post(`/api/Admin/SenateConstituencies/Add`, constituency);
    },
    DeleteConstituency(constituencyId) {
        return axios.post(`/api/Admin/Constituencies/${constituencyId}/Delete`);
    },
    DeleteSenateConstituency(constituencyId) {
        return axios.post(`/api/Admin/SenateConstituencies/${constituencyId}/Delete`);
    },
    GetAllConstituencies() {
        return axios.get(`/Api/Admin/Constituencies/GetAll`);
    },
    GetAllSenateConstituencies() {
        return axios.get(`/Api/Admin/SenateConstituencies/GetAll`);
    },
    GetConstituencyBasedOn(constituencyId) {
        return axios.get(`/Api/Admin/Constituencies/Get/${constituencyId}`);
    },

    GetSenateConstituencyBasedOn(officeId) {
        return axios.get(`/Api/Admin/SenateConstituencies/Get/${officeId}`);
    },

    GetConstituenciesDetalsChairs(ConstituencyId) {
        return axios.get(`/Api/Admin/ConstituencyDetails/${ConstituencyId}/Chairs`);
    },
    GetAConstituencyBasedOn(regionId) {
        return axios.get(`/Api/Admin/Constituencies/Get/${regionId}/Region`);
    },
    GetConstituencyPagination(pageNo, pageSize) {
        return axios.get(`/Api/Admin/Constituencies/Gets?pageNo=${pageNo}&pageSize=${pageSize}`);
    },
    GetSenateConstituencyPagination(pageNo, pageSize) {
        return axios.get(`/Api/Admin/SenateConstituencies/Get?pageNo=${pageNo}&pageSize=${pageSize}`);
    },
    UpdateConstituency(constituency) {
        return axios.post(`/Api/Admin/Constituencies/Edit`, constituency);
    },
    UpdateSenateConstituency(constituency) {
        return axios.post(`/Api/Admin/SenateConstituencies/Edit`, constituency);
    },

    GetConstituenciesKeyValue() {
        return axios.get(`/Api/Admin/Constituencies/GetAllConstituencies`);
    },
    GetSenateConstituenciesKeyValue() {
        return axios.get(`/Api/Admin/SenateConstituencies/GetAllConstituencies`);
    },

    // ************************ ConstituencyDetails **************************
    AddConstituencyDetails(constituencyDetials) {
        return axios.post(`/api/Admin/ConstituencyDetails/Add`, constituencyDetials);
    },
    AddSenateConstituencyDetails(constituencyDetials) {
        return axios.post(`/api/Admin/SenateConstituencyDetails/Add`, constituencyDetials);
    },
    GetCompetitionTypes() {
        return axios.get(`/Api/Admin/ConstituencyDetails/GetCompetitionTypes`);
    },
    GetConstituencyDetails() {
        return axios.get(`/api/Admin/ConstituencyDetails/All`);
    },

    //GetSupporters
    GetSupporters(queryParams) {
        return axios.get('/api/admin/supporters', {
            params: queryParams
        });
    },

    GetSenateConstituencyDetails() {
        return axios.get(`/api/Admin/SenateConstituencyDetails/All`);
    },

    DeleteConstituencyDetail(id) {
        return axios.post(`/api/Admin/ConstituencyDetails/${id}/Delete`);
    },
    HideConstituencyDetail(id) {
        return axios.post(`/api/Admin/ConstituencyDetails/${id}/Hide`);
    },
    ViewtituencyDetail(id) {
        return axios.post(`/api/Admin/ConstituencyDetails/${id}/View`);
    },
    DeleteSenateConstituencyDetail(id) {
        return axios.post(`/api/Admin/SenateConstituencyDetails/${id}/Delete`);
    },
    GetConstituencyDetailsBasedOn(constituencyDetailsId) {
        return axios.get(`/api/Admin/ConstituencyDetails/Get/${constituencyDetailsId}`);
    },
    GetSenaetConstituencyDetailsBasedOn(constituencyDetailsId) {
        return axios.get(`/api/Admin/SenateConstituencyDetails/Get/${constituencyDetailsId}`);
    },
    GetAllConstituencyDetailsBasedOn(constituencyId) {
        return axios.get(`/api/Admin/ConstituencyDetails/Get/Constituency/${constituencyId}`);
    },
    GetAllSenateConstituencyDetailsBasedOn(constituencyId) {
        return axios.get(`/api/Admin/SenateConstituencyDetails/Get/Constituency/${constituencyId}`);
    },
    GetAllConstituencyDetailsBasedOnOffice(id) {
        return axios.get(`/api/Admin/ConstituencyDetails/Get/ConstituencyDetailsBy/${id}`);
    },
    // GetAllSenateConstituencyDetailsBasedOnOffice(id) {
    //     return axios.get(`/api/Admin/SenateConstituencyDetails/Get/Offices/${id}`);
    // },
    // /api/Admin/ConstituencyDetails/GetCompetitionTypes/244 
    GetCompetitionTypesByConstituencyDetailsId(id) {
        return axios.get(`/api/Admin/ConstituencyDetails/GetCompetitionTypes/${id}`);
    },
    GetSenateCompetitionTypesByConstituencyDetailsId(id) {
        return axios.get(`/api/Admin/SenateConstituencyDetails/GetCompetitionTypes/${id}`);
    },
    GetConstituencyDetailsPagination(pageNo, pageSize, ConstituencyId) {
        return axios.get(`/api/Admin/ConstituencyDetails/GET?pageNo=${pageNo}&pageSize=${pageSize}&ConstituencyId=${ConstituencyId}`);
    },
    GetSenateConstituencyDetailsPagination(pageNo, pageSize, ConstituencyId) {
        return axios.get(`/api/Admin/SenateConstituencyDetails/GET?pageNo=${pageNo}&pageSize=${pageSize}&ConstituencyId=${ConstituencyId}`);
    },
    UpdateConstituencyDetail(constituencyDetail) {
        return axios.post(`/api/Admin/ConstituencyDetails/Edit`, constituencyDetail);
    },
    UpdateSenateConstituencyDetail(constituencyDetail) {
        return axios.post(`/api/Admin/SenateConstituencyDetails/Edit`, constituencyDetail);
    },
    GetConstituencyDetailsByOfficeId(OfficeId) {
        return axios.get(`/api/Admin/ConstituencyDetails/Get/ConstituencyDetailsBy/${OfficeId}`);
    },
    GetSenateConstituencyDetailsByOfficeId(OfficeId) {
        return axios.get(`/api/Admin/SenateConstituencyDetails/Get/ConstituencyDetailsBy/${OfficeId}`);
    },
    // ************************ Centers **************************
    AddCenter(center) {
        return axios.post(`/api/Admin/Centers/Add`, center);
    },
    GetCentersPagination(pageNo, pageSize) {
        return axios.get(`/api/Admin/Centers/Get?pageNo=${pageNo}&pageSize=${pageSize}`);
    },
    DeleteCenter(centerId) {
        return axios.post(`/api/Admin/Centers/${centerId}/Delete`);
    },
    GetCenter(centerId) {
        return axios.get(`/api/Admin/Centers/Get/${centerId}`);
    },
    GetAllCenters() {
        return axios.get(`/Api/Admin/Centers/Get/All`);
    },
    GetCentersBasedOn(constituencyDetialId) {
        return axios.get(`/Api/Admin/Centers/Get/ConstituencyDetails/${constituencyDetialId}`);
    },
    UpdateCenter(center) {
        return axios.post(`/api/Admin/Centers/Edit`, center);
    },
    // ************************ Stations **************************
    GetStations(centerId, pageNo, pageSize) {
        return axios.get(`/api/Admin/Stations/Get?centerId=${centerId}&pageNo=${pageNo}&pageSize=${pageSize}`);
    },
    CreateStations(stations) {
        return axios.post(`/api/Admin/Stations/Add`, stations);
    },
    DeleteStation(stationId) {
        return axios.post(`/api/Admin/Stations/Delete/${stationId}`);
    },
    GetStationBasedOn(stationId) {
        return axios.get(`/api/Admin/Stations/Get/${stationId}`);
    },
    UpdateStation(station) {
        return axios.post(`/api/Admin/Stations/Edit`, station);
    },
    // ************************ Candidates **************************
    ConfirmCandidate(CandidateId) {
        return axios.get(`/api/Admin/Candidates/ConfirmCandidates/${CandidateId}`);
    },

    FirstReport(CandidateId) {
        return axios.get(`/api/Admin/Candidates/FirstReport/${CandidateId}`, {
            responseType: "blob",
        });
    },

    ConfirmSenateCandidate(CandidateId) {
        return axios.get(`/api/Admin/SenateCandidates/ConfirmCandidates/${CandidateId}`);
    },

    FirstSenateReport(CandidateId) {
        return axios.get(`/api/Admin/SenateCandidates/FirstReport/${CandidateId}`);
    },

    SenateFirstReport(CandidateId) {
        return axios.get(`/api/Admin/SenateCandidates/FirstReport/${CandidateId}`);
    },
    GetCandidateById(CandidateId) {
        return axios.get(`/api/Admin/Candidates/GetCandidateByID?CandidateId=${CandidateId}`);
    },
    PrintCandidateLists(Status, ConstituencyId, SubConstituencyId, CompetitionType) {
        return axios.get(`/api/Admin/Candidates/PrintCandidateLists?Status=${Status}&ConstituencyId=${ConstituencyId}&SubConstituencyId=${SubConstituencyId}&CompetitionType=${CompetitionType}`, {
            responseType: "blob",
        });
    },
    PrintSenateCandidateLists(Status, ConstituencyId, SubConstituencyId, CompetitionType) {
        return axios.get(`/api/Admin/SenateCandidates/PrintCandidateLists?Status=${Status}&ConstituencyId=${ConstituencyId}&SubConstituencyId=${SubConstituencyId}&CompetitionType=${CompetitionType}`);
    },
    CandidateStatus(CandidateId, Status, LegalAmendments) {
        return axios.get(`/api/Admin/Candidates/CandidateStatus?CandidateId=${CandidateId}&Status=${Status}&LegalAmendments=${LegalAmendments}`);
    },
    // CandidateStatusByEntityId 
    //[HttpGet("CandidateStatusByEntityId")]
    //public IActionResult CandidateStatusByEntityId(long EntityId, short? status, short LegalAmendments)
    CandidateStatusByEntityId(EntityId, Status, LegalAmendments) {
        return axios.get(`/api/Admin/Entities/CandidateStatusByEntityId?EntityId=${EntityId}&Status=${Status}&LegalAmendments=${LegalAmendments}`);
    },


    SenateCandidateStatus(CandidateId, Status, LegalAmendments) {
        return axios.get(`/api/Admin/SenateCandidates/CandidateStatus?CandidateId=${CandidateId}&Status=${Status}&LegalAmendments=${LegalAmendments}`);
    },
    MoveTheSingleCandidateBackToFinalList(CandidateId) {
        return axios.get(`/api/Admin/Candidates/MoveBackToFinalList/${CandidateId}`);
    },
    MoveTheSingleSenateCandidateBackToFinalList(CandidateId) {
        return axios.get(`/api/Admin/SenateCandidates/MoveBackToFinalList/${CandidateId}`);
    },
    MoveToFirstList(CandidateId) {
        return axios.get(`/api/Admin/Candidates/MoveToFirstList/${CandidateId}`);
    },
    MoveSenateToFirstList(CandidateId) {
        return axios.get(`/api/Admin/SenateCandidates/MoveToFirstList/${CandidateId}`);
    },
    CandidateList(pageNo, pageSize, Status, ConstituencyId, SubConsituencyId, CompetitionType) {
        return axios.get(`/api/Admin/Candidates/CandidateList?pageNo=${pageNo}&pageSize=${pageSize}&Status=${Status}&ConstituencyId=${ConstituencyId}&SubConsituencyId=${SubConsituencyId}&CompetitionType=${CompetitionType}`);
    },
    SenateCandidateList(pageNo, pageSize, Status, ConstituencyId, SubConsituencyId, CompetitionType) {
        return axios.get(`/api/Admin/SenateCandidates/CandidateList?pageNo=${pageNo}&pageSize=${pageSize}&Status=${Status}&ConstituencyId=${ConstituencyId}&SubConsituencyId=${SubConsituencyId}&CompetitionType=${CompetitionType}`);
    },
    CandidateListForEndorsments(pageNo, pageSize) {
        return axios.get(`/api/Admin/PresidentialCandidates/CandidateListForEndorsments?pageNo=${pageNo}&pageSize=${pageSize}`);
    },
    //FetchEndorsementDetails   [HttpGet("endorsements/{userId}")]    [Route("api/admin/supporters")]
    fetchEndorsementDetails(userId) {
        return axios.get(`/api/admin/supporters/endorsements/${userId}`);
    },
    //// GET: api/admin/supporters/supporter-details/{candidateId}
    fetchSupporterDetails(candidateId) {
        return axios.get(`/api/admin/supporters/details-by-candidate/${candidateId}`);
    },
    // Check the validity of a SupporterSecretCode
    CheckSupporterCode(secretCode) {
        return axios.get('/api/admin/supporters/check-supporter-code', {
            params: { secretCode: secretCode }
        });
    },
    // Confirm supporter check and assign to a candidate
    ConfirmSupporterCheck(confirmSupporterCheckDto) {
        return axios.post('/api/admin/supporters/confirm-supporter-check', confirmSupporterCheckDto);
    },
    // Fetch entity supporter details by entityId
    fetchEntitySupporterDetails(entityId) {
        return axios.get(`/api/admin/supporters-entites/details-by-entity/${entityId}`);
    },
    // Check the validity of a SupporterSecretCode for entities
    checkEntitySupporterCode(secretCode) {
        return axios.get('/api/admin/supporters-entites/check-supporter-code', {
            params: { secretCode: secretCode }
        });
    },
    // Confirm supporter check and assign to an entity
    confirmEntitySupporterCheck(confirmSupporterCheckDto) {
        return axios.post('/api/admin/supporters-entites/confirm-supporter-check', confirmSupporterCheckDto);
    },
    GetCandidate(CandidateId) {
        return axios.get(`/api/Admin/Candidates/Get/${CandidateId}`);
    },
    GetSenateCandidate(CandidateId) {
        return axios.get(`/api/Admin/SenateCandidates/Get/${CandidateId}`);
    },
    GetCandidatesByEntityId(id) {
        return axios.get(`/api/Admin/Candidates/${id}/Entity`);
    },
    AddCandidateToEntity(candidate) {
        return axios.post(`/api/Admin/Candidates/AddCandidateToEntity`, candidate);
    },
    GetCandidates(pageNo, pageSize, SubConstituencyId) {
        return axios.get(`/api/Admin/Candidates/Get?pageNo=${pageNo}&pageSize=${pageSize}&SubConstituencyId=${SubConstituencyId}`);
    },
    RegisterCandidateLevelOne(nationalId) {
        return axios.get(`/api/Admin/Candidates/NationalId/${nationalId}`);
    },
    RegisterSenateCandidateLevelOne(nationalId) {
        return axios.get(`/api/Admin/SenateCandidates/NationalId/${nationalId}`);
    },

    UploadCandidateData(object) {
        return axios.post(`/api/Admin/Candidates/Data`, object);
    },
    UploadFiles(object) {
        return axios.post(`/api/Admin/Candidates/UploadDocuments`, object);
    },
    UploadDocuments(object) {
        return axios.post(`/api/Admin/Candidates/Documents`, object);
    },
    AddDocumentStatus(object) {
        return axios.post(`/api/Admin/Candidates/AddDocumentStatus`, object);
    },

    UploadCandidateData2(object) {
        return axios.post(`/api/Admin/SenateCandidates/Data`, object);
    },
    UploadFiles2(object) {
        return axios.post(`/api/Admin/SenateCandidates/UploadDocuments`, object);
    },
    UploadDocuments2(object) {
        return axios.post(`/api/Admin/SenateCandidates/Documents`, object);
    },
    AddDocumentStatus2(object) {
        return axios.post(`/api/Admin/SenateCandidates/AddDocumentStatus`, object);
    },
    AddDocumentStatusSenate(object) {
        return axios.post(`/api/Admin/SenateCandidates/AddDocumentStatus`, object);
    },
    UpdateCandidate(candidate) {
        return axios.post(`/api/Admin/Candidates/Edit`, candidate);
    },

    UpdateSenateCandidate(candidate) {
        return axios.post(`/api/Admin/SenateCandidates/Edit`, candidate);
    },

    UploadCandidateAttachments(candidate) {
        return axios.post(`/api/Admin/Candidates/Attachments`, candidate);
    },
    UpdateCandidateAttachments(candidate) {
        return axios.post(`/api/Admin/Candidates/Attachments/Edit`, candidate);
    },
    UpdateSenateCandidateAttachments(candidate) {
        return axios.post(`/api/Admin/SenateCandidates/Attachments/Edit`, candidate);
    },
    GetCandidateAttachment(attachment) {
        return axios.post(`/api/Admin/Candidates/Get/Attachment`, attachment);
    },
    GetCandidateInfo(CandidateId) {
        return axios.get(`/api/Admin/Candidates/Complete/${CandidateId}`);
    },

    GetSenateCandidateInfo(CandidateId) {
        return axios.get(`/api/Admin/SenateCandidates/Complete/${CandidateId}`);
    },

    AddCandidateUser(User) {
        return axios.post('/Api/Admin/Candidates/Add/User', User);
    },
    GetCandidateUser(candidateId) {
        return axios.get(`/Api/Admin/Candidates/GetUser/${candidateId}`);
    },
    GetCandidateIdByNid(Nid) {

        return axios.get(`/api/Admin/Candidates?nationalId=${Nid}`);
    },
    SearchCandidates(pageNo, pageSize, constituencyId, SubConstituencyId, candidateName, /*candidateNumber, */nid, HasSupporterCode) {
        // check if HasSupporterCode is null or undefined and set to null if so
        if (HasSupporterCode === null || HasSupporterCode === undefined || HasSupporterCode === '' || HasSupporterCode === 'null' || HasSupporterCode === 'undefined') {
            // set hasSupporterCode to nothing
            HasSupporterCode = '';
        }

        return axios.get(`/Api/Admin/Candidates/SearchCandidates?pageNo=${pageNo}&pageSize=${pageSize}&HasSupporterCode=${HasSupporterCode}&constituencyId=${constituencyId}&SubConstituencyId=${SubConstituencyId}&candidateName=${candidateName}&nid=${nid}`);
    },

    SearchSenateCandidates(pageNo, pageSize, constituencyId, SubConstituencyId, candidateName, /*candidateNumber, */nid) {
        return axios.get(`/Api/Admin/SenateCandidates/SearchCandidates?pageNo=${pageNo}&pageSize=${pageSize}&constituencyId=${constituencyId}&SubConstituencyId=${SubConstituencyId}&candidateName=${candidateName}&nid=${nid}`);
    },

    SearchCandidate(object) {
        return axios.post(`/Api/Admin/Candidates/SearchCandidate`, object);
    },
    GetEndorsementsByCandidateId(id) {
        return axios.get(`/Api/Admin/Candidates/GetEndorsementsByCandidateId?id=${id}`);
    },
    GetCandidatesByConstituencyId(ConstituencyId, pageNo, pageSize) {
        return axios.get(`/Api/Admin/Candidates/Get/Constituency?ConstituencyId=${ConstituencyId}&pageNo=${pageNo}&pageSize=${pageSize}`);
    },
    ExportFirstCandidateListToExcel() {
        return axios.get(`/Api/Admin/Candidates/Export/FirstList/Excel`);
    },
    ExportFirstSenateCandidateListToExcel() {
        return axios.get(`/Api/Admin/SenateCandidates/Export/FirstList/Excel`);
    },
    ExportFinalCandidateListToExcel() {
        return axios.get(`/Api/Admin/Candidates/Export/FinalList/Excel`);
    },
    ExportFinalSenateCandidateListToExcel() {
        return axios.get(`/Api/Admin/SenateCandidates/Export/FinalList/Excel`);
    },
    ExportCandidateListToExcel() {
        return axios.get(`/Api/Admin/Candidates/Export/Excel`);
    },
    ExportSenateCandidateListToExcel() {
        return axios.get(`/Api/Admin/SenateCandidates/Export/Excel`);
    },
    // ************************ PresidentalCandidates **************************
    ConfirmPresidentalCandidate(CandidateIt) {
        return axios.get(`/api/Admin/PresidentialCandidates/ConfirmCandidates/${CandidateIt}`);
    },
    FirstPresidentalCandidatesReport(Nid) {
        return axios.get(`/api/Admin/PresidentialCandidates/FirstReport/${Nid}`);
    },
    GetPresidentialCandidateById(CandidateId) {
        return axios.get(`/api/Admin/PresidentialCandidates/GetCandidateByID?CandidateId=${CandidateId}`);
    },
    PrintPresidentialCandidateLists(Status) {
        return axios.get(`/api/Admin/PresidentialCandidates/PrintCandidateLists?Status=${Status}`);
    },
    PresidentialCandidateStatus(CandidateId, Status, LegalAmendments) {
        return axios.get(`/api/Admin/PresidentialCandidates/PresidentialCandidatestatus?CandidateId=${CandidateId}&Status=${Status}&LegalAmendments=${LegalAmendments}`);
    },
    MoveTheCandidateBackToPresidentialFinalList(CandidateId) {
        return axios.get(`/api/Admin/PresidentialCandidates/MoveBackToFinalList/${CandidateId}`);
    },
    MovePresidentialCandidateToFirstList(CandidateId) {
        return axios.get(`/api/Admin/PresidentialCandidates/MoveToFirstList/${CandidateId}`);
    },

    PresidentialCandidateList(pageNo, pageSize, Status) {
        return axios.get(`/api/Admin/PresidentialCandidates/CandidateList?pageNo=${pageNo}&pageSize=${pageSize}&Status=${Status}`);
    },
    GetPresidentialCandidate(CandidateId) {
        return axios.get(`/api/Admin/PresidentialCandidates/Get/${CandidateId}`);
    },
    GetPresidentialCandidatesByEntityId(id) {
        return axios.get(`/api/Admin/PresidentialCandidates/${id}/Entity`);
    },
    AddPresidentialCandidateToEntity(candidate) {
        return axios.post(`/api/Admin/PresidentialCandidates/AddCandidateToEntity`, candidate);
    },
    GetPresidentialCandidates(pageNo, pageSize) {
        return axios.get(`/api/Admin/PresidentialCandidates/Get?pageNo=${pageNo}&pageSize=${pageSize}`);
    },
    RegisterPresidentialCandidateLevelOne(nationalId) {

        return axios.get(`/api/Admin/PresidentialCandidates/NationalId/${nationalId}`);
    },
    RegisterPresidentialCandidateContact(object) {

        return axios.post(`/api/Admin/PresidentialCandidates/SendVerifyCode`, object);
    },
    UploadPresidentialCandidateData(object) {
        return axios.post(`/api/Admin/PresidentialCandidates/Data`, object);

    },
    UploadPresidentialCandidateFiles(object) {
        return axios.post(`/api/Admin/PresidentialCandidates/UploadDocuments`, object);

    },
    UploadPresidentialCandidateDocuments(object) {
        return axios.post(`/api/Admin/PresidentialCandidates/Documents`, object);

    },
    AddPresidentialCandidateDocumentStatus(object) {
        return axios.post(`/api/Admin/PresidentialCandidates/AddDocumentStatus`, object);
    },
    UpdatePresidentialCandidate(candidate) {
        return axios.post(`/api/Admin/PresidentialCandidates/Edit`, candidate);
    },
    UploadPresidentialCandidateAttachments(candidate) {
        return axios.post(`/api/Admin/PresidentialCandidates/Attachments`, candidate);
    },
    UpdatePresidentialCandidateAttachments(candidate) {
        return axios.post(`/api/Admin/PresidentialCandidates/Attachments/Edit`, candidate);
    },
    GetPresidentialCandidateAttachment(attachment) {
        return axios.post(`/api/Admin/PresidentialCandidates/Get/Attachment`, attachment);
    },
    GetPresidentialCandidateInfo(NationalId) {
        return axios.get(`/api/Admin/PresidentialCandidates/Complete/${NationalId}`);
    },
    AddPresidentialCandidateUser(User) {
        return axios.post('/Api/Admin/PresidentialCandidates/Add/User', User);
    },
    GetPresidentialCandidateUser(candidateId) {
        return axios.get(`/Api/Admin/PresidentialCandidates/GetUser/${candidateId}`);
    },
    GetPresidentialCandidateIdByNid(Nid) {

        return axios.get(`/api/Admin/PresidentialCandidates?nationalId=${Nid}`);
    },
    SearchPresidentialCandidates(pageNo, pageSize, candidateName, nid) {
        return axios.get(`/Api/Admin/PresidentialCandidates/SearchPresidentialCandidates?pageNo=${pageNo}&pageSize=${pageSize}&candidateName=${candidateName}&nid=${nid}`);
    },
    SearchPresidentialCandidate(object) {
        return axios.post(`/Api/Admin/PresidentialCandidates/SearchCandidate`, object);
    },
    GetEndorsementsByPresidentialCandidateId(id) {
        return axios.get(`/Api/Admin/PresidentialCandidates/GetEndorsementsByCandidateId?id=${id}`);
    },
    ExportPresidentialCandidateListToExcel() {
        return axios.get(`/Api/Admin/PresidentialCandidates/Export/Excel`);
    },
    ExportPresidentialFirstCandidateListToExcel() {
        return axios.get(`/Api/Admin/PresidentialCandidates/Export/FirstList/Excel`);
    },
    ExportPresidentialFinalCandidateListToExcel() {
        return axios.get(`/Api/Admin/PresidentialCandidates/Export/FinalList/Excel`);
    },
    RearrangeTheNumbering() {
        return axios.get(`/Api/Admin/PresidentialCandidates/RearrangeTheNumbering`);
    },
    PrintPresidentialCandidatesEndorsements() {
        return axios.get(`/Api/Admin/PresidentialCandidates/PrintEndorsements`);
    },
    //DeleteCompany(CompanyId) {
    //    axios.defaults.headers.common['Authorization'] = 'Bearer ' + document.querySelector('meta[name="api-token"]').getAttribute('content');
    //    return axios.post(`/Api/Admin/Companies/${CompanyId}/delete`);
    //},
    //GetCountries(pageNo, pageSize) {
    //    axios.defaults.headers.common['Authorization'] = 'Bearer ' + document.querySelector('meta[name="api-token"]').getAttribute('content');
    //    return axios.get(`/Api/Admin/Countries/Get?pageno=${pageNo}&pagesize=${pageSize}`);
    //},
    //GetAllCountries() {
    //    axios.defaults.headers.common['Authorization'] = 'Bearer ' + document.querySelector('meta[name="api-token"]').getAttribute('content');
    //    return axios.get(`/Api/Admin/Countries/GetAllCountries`);
    //},

    //AddCountries(Country) {
    //    axios.defaults.headers.common['Authorization'] = 'Bearer ' + document.querySelector('meta[name="api-token"]').getAttribute('content');
    //    return axios.post(`/Api/Admin/Countries/Add`, Country);
    //},
    //DeleteCountry(CountryId) {
    //    axios.defaults.headers.common['Authorization'] = 'Bearer ' + document.querySelector('meta[name="api-token"]').getAttribute('content');
    //    return axios.post(`/Api/Admin/Countries/${CountryId}/delete`);
    //},

    //GetCities(pageNo, pageSize, CountryId) {
    //    axios.defaults.headers.common['Authorization'] = 'Bearer ' + document.querySelector('meta[name="api-token"]').getAttribute('content');
    //    return axios.get(`/Api/Admin/Cities/Get?pageno=${pageNo}&pagesize=${pageSize}&CountryId=${CountryId}`);
    //},
    ////GetCityByCountryId(CountryId) {
    ////    axios.defaults.headers.common['Authorization'] = 'Bearer ' + document.querySelector('meta[name="api-token"]').getAttribute('content');
    ////    return axios.get(`/Api/Admin/Cities/GetCityByCountryId?CountryId=${CountryId}`);
    ////},
    //AddCities(Cities) {
    //    axios.defaults.headers.common['Authorization'] = 'Bearer ' + document.querySelector('meta[name="api-token"]').getAttribute('content');
    //    return axios.post(`/Api/admin/Cities/Add`, Cities);
    //},
    //DeleteCity(CityId) {
    //    axios.defaults.headers.common['Authorization'] = 'Bearer ' + document.querySelector('meta[name="api-token"]').getAttribute('content');
    //    return axios.post(`/Api/Admin/Cities/${CityId}/delete`);
    //},

    GetBranches(pageNo, pageSize) {
        return axios.get(`/Api/Admin/Branches/Get?pageno=${pageNo}&pagesize=${pageSize}`);
    },
    DeleteBranche(BranchId) {
        return axios.post(`/Api/Admin/Branches/${BranchId}/Delete`);
    },
    AddBranch(Branch) {
        return axios.post(`/Api/Admin/Branches/Add`, Branch);
    },
    SearchCandidatesInitialLists(pageNo, pageSize, constituencyId, SubConstituencyId, CompetitionType, isPrint) {
        return axios.get(`/Api/Admin/Candidates/SearchCandidatesInitialLists?pageNo=${pageNo}&pageSize=${pageSize}&constituencyId=${constituencyId}&SubConstituencyId=${SubConstituencyId}&CompetitionType=${CompetitionType}&isPrint=${isPrint}`);
    },

    // ************************ EntityCandidates **************************
    EntityCandidateLists(ConstituencyId, subConstituencyId) {
        return axios.get(`/api/Admin/EntityCandidates/EntityCandidateLists/${ConstituencyId}/${subConstituencyId}`, {
            responseType: "blob",
        });



    },
    PrintEntityCandidateFinalLists(ConstituencyId, SubconstituencyId) {
        return axios.get(`/api/Admin/EntityCandidates/EntityCandidateFinalLists/${ConstituencyId}/${SubconstituencyId}`, {
            responseType: "blob",
        });
    },
    GetCandidatesOfEntitiesBy(pageNo, pageSize, EntityId) {
        return axios.get(`/api/Admin/EntityCandidates/Get/Candidate?pageNo=${pageNo}&pageSize=${pageSize}&EntityId=${EntityId || 0}`);
    },
    GetEntityCandidateBy(CandidateId) {
        return axios.get(`/api/Admin/EntityCandidates/Get/${CandidateId}`);
    },
    GetCandidateFirstList(pageNo, pageSize, ConstituencyId, SubConstituencyId, status) {
        return axios.get(`/api/Admin/EntityCandidates/Get/EntityCandidateFirstLists?pageNo=${pageNo}&pageSize=${pageSize}&constituencyId=${ConstituencyId || 0}&subConstituencyId=${SubConstituencyId || 0}&status=${status}`);
    },
    GetEntityCandidates(pageNo, pageSize, SubConstituencyId) {
        return axios.get(`/api/Admin/EntityCandidates/Get?pageNo=${pageNo}&pageSize=${pageSize}&SubConstituencyId=${SubConstituencyId}`);
    },
    EntitiCandidateStatus(CandidateId, Status, LegalAmendments) {
        return axios.get(`/api/Admin/EntityCandidates/EntitiCandidateStatu?CandidateId=${CandidateId}&Status=${Status}&LegalAmendments=${LegalAmendments}`);
    },
    MoveTheCandidateBackToFinalList(CandidateId) {
        return axios.get(`/api/Admin/EntityCandidates/MoveBackToFinalList/${CandidateId}`);
    },
    MoveEntityCandidateToFirstList(CandidateId) {
        return axios.get(`/api/Admin/EntityCandidates/MoveToFirstList/${CandidateId}`);
    },
    MoveCanidateNumberUp(CandidateId) {
        return axios.get(`/api/Admin/EntityCandidates/MoveCanidateNumberUp/${CandidateId}`);
    },
    MoveCanidateNumberDown(CandidateId) {
        return axios.get(`/api/Admin/EntityCandidates/MoveCanidateNumberDown/${CandidateId}`);
    },
    RegisterEntityCandidateLevelOne(nationalId, entityId) {

        return axios.get(`/api/Admin/EntityCandidates/NationalId/${nationalId}/${entityId}`);
    },

    UploadEntityCandidateData(object) {
        return axios.post(`/api/Admin/EntityCandidates/Data`, object);

    },
    UploadEntityCandidatesFiles(object) {
        return axios.post(`/api/Admin/EntityCandidates/UploadDocuments`, object);

    },
    UploadEntityCandidatesDocuments(object) {
        return axios.post(`/api/Admin/EntityCandidates/Documents`, object);

    },
    UpdateEntityCandidate(candidate) {
        return axios.post(`/api/Admin/EntityCandidates/Edit`, candidate);
    },
    UploadEntityCandidateAttachments(candidate) {
        return axios.post(`/api/Admin/EntityCandidates/Attachments`, candidate);
    },
    UpdateEntityCandidatesAttachments(candidate) {
        return axios.post(`/api/Admin/EntityCandidates/Attachments/Edit`, candidate);
    },
    AddEntityCandidatesDocumentStatus(object) {
        return axios.post(`/api/Admin/EntityCandidates/AddDocumentStatus`, object);
    },
    GetEntityCandidateAttachment(attachment) {
        return axios.post(`/api/Admin/EntityCandidates/Get/Attachment`, attachment);
    },
    GetEntityCandidateInfo(NationalId) {
        return axios.get(`/api/Admin/EntityCandidates/Complete/${NationalId}`);
    },
    AddEntityCandidateUser(User) {
        return axios.post('/Api/Admin/EntityCandidates/Add/User', User);
    },
    GetEntityCandidateUser(candidateId) {
        return axios.get(`/Api/Admin/EntityCandidates/GetUser/${candidateId}`);
    },
    GetEntityCandidateIdByNid(Nid) {

        return axios.get(`/api/Admin/EntityCandidates?nationalId=${Nid}`);
    },
    SearchEntityCandidates(pageNo, pageSize, constituencyId, SubConstituencyId, candidateName, /*candidateNumber, */nid) {

        return axios.get(`/Api/Admin/EntityCandidates/SearchCandidates?pageNo=${pageNo}&pageSize=${pageSize}&constituencyId=${constituencyId}&SubConstituencyId=${SubConstituencyId}&candidateName=${candidateName}&nid=${nid}`);
    },
    GetEndorsementsByEntityCandidateId(id) {
        return axios.get(`/Api/Admin/EntityCandidates/GetEndorsementsByCandidateId?id=${id}`);
    },
    ExportEntityCandidateListToExcel() {
        return axios.get(`/Api/Admin/EntityCandidates/Export/Excel`);
    },
    ExportFirstEntityCandidateListToExcel() {
        return axios.get(`/Api/Admin/EntityCandidates/Export/FirstList/Excel`);
    },
    ExportFinalEntityCandidateListToExcel() {
        return axios.get(`/Api/Admin/EntityCandidates/Export/FinalList/Excel`);
    },
    //DeleteCompany(CompanyId) {
    //    axios.defaults.headers.common['Authorization'] = 'Bearer ' + document.querySelector('meta[name="api-token"]').getAttribute('content');
    //    return axios.post(`/Api/Admin/Companies/${CompanyId}/delete`);
    //},
    //GetCountries(pageNo, pageSize) {
    //    axios.defaults.headers.common['Authorization'] = 'Bearer ' + document.querySelector('meta[name="api-token"]').getAttribute('content');
    //    return axios.get(`/Api/Admin/Countries/Get?pageno=${pageNo}&pagesize=${pageSize}`);
    //},
    //GetAllCountries() {
    //    axios.defaults.headers.common['Authorization'] = 'Bearer ' + document.querySelector('meta[name="api-token"]').getAttribute('content');
    //    return axios.get(`/Api/Admin/Countries/GetAllCountries`);
    //},

    //AddCountries(Country) {
    //    axios.defaults.headers.common['Authorization'] = 'Bearer ' + document.querySelector('meta[name="api-token"]').getAttribute('content');
    //    return axios.post(`/Api/Admin/Countries/Add`, Country);
    //},
    //DeleteCountry(CountryId) {
    //    axios.defaults.headers.common['Authorization'] = 'Bearer ' + document.querySelector('meta[name="api-token"]').getAttribute('content');
    //    return axios.post(`/Api/Admin/Countries/${CountryId}/delete`);
    //},

    //GetCities(pageNo, pageSize, CountryId) {
    //    axios.defaults.headers.common['Authorization'] = 'Bearer ' + document.querySelector('meta[name="api-token"]').getAttribute('content');
    //    return axios.get(`/Api/Admin/Cities/Get?pageno=${pageNo}&pagesize=${pageSize}&CountryId=${CountryId}`);
    //},
    ////GetCityByCountryId(CountryId) {
    ////    axios.defaults.headers.common['Authorization'] = 'Bearer ' + document.querySelector('meta[name="api-token"]').getAttribute('content');
    ////    return axios.get(`/Api/Admin/Cities/GetCityByCountryId?CountryId=${CountryId}`);
    ////},
    //AddCities(Cities) {
    //    axios.defaults.headers.common['Authorization'] = 'Bearer ' + document.querySelector('meta[name="api-token"]').getAttribute('content');
    //    return axios.post(`/Api/admin/Cities/Add`, Cities);
    //},
    //DeleteCity(CityId) {
    //    axios.defaults.headers.common['Authorization'] = 'Bearer ' + document.querySelector('meta[name="api-token"]').getAttribute('content');
    //    return axios.post(`/Api/Admin/Cities/${CityId}/delete`);
    //},

    //************************ Offices **************************

    GetAllOffices() {
        return axios.get(`/Api/Admin/Offices/GetAllOffices`);
    },
    GetOffice(pageNo, pageSize) {
        return axios.get(`/Api/Admin/Offices/Get?pageno=${pageNo}&pagesize=${pageSize}`);
    },
    deleteOffice(id) {
        return axios.post(`/Api/Admin/Offices/${id}/Delete`);
    },
    AddOffice(OfficeData) {
        return axios.post(`/Api/Admin/Offices/Add`, OfficeData);
    },
    GetAllOfficesBasedOnConstituencyDetails(subconstituencyId) {
        return axios.get(`/Api/Admin/Offices/Get/BasedOnSubconstituency/${subconstituencyId}`);
    },
    //BasedOnSenateSubconstituency
    GetAllOfficesBasedOnSenateConstituencyDetails(subconstituencyId) {
        return axios.get(`/Api/Admin/Offices/Get/BasedOnSenateSubconstituency/${subconstituencyId}`);
    },
    //************************ Represenatives Of Candidates **************************

    AddCandidateRepresentatives(candidateRepresentatives) {
        return axios.post(`/Api/Admin/Representatives/Candidate/Add`, candidateRepresentatives);
    },
    GetCandidateRepresentatives(candidateIs) {
        return axios.get(`/Api/Admin/Representatives/GetRepresentativesBy/${candidateIs}`);
    },
    AddCandidateRepresentative(form) {
        return axios.post(`/Api/Admin/Representatives/Candidate/Add`, form);
    },

    AddFromCandidateRepresentative(form) {
        return axios.post(`/Api/Admin/Representatives/Candidate/AddFrom`, form);
    },
    AddFromCandidateRepresentativeSignl(form) {
        return axios.post(`/Api/Admin/Representatives/Candidate/AddFromSignl`, form);
    },
    AddFromCandidateRepresentativeSignl2(form) {
        return axios.post(`/Api/Admin/Representatives/SenateCandidates/AddFromSignl`, form);
    },
    AddFromPresidentialCandidateRepresentativeSignl(form) {
        return axios.post(`/Api/Admin/Representatives/PresidentialCandidate/AddFromSignl`, form);
    },
    //************************ Legal Amendments **************************
    GetCandidatesFromLegalAmendments(object) {
        return axios.get(`/Api/Admin/LegalAmendments/Get?pageNo=${object.pageNo}&pageSize=${object.pageSize}&SearchInput=${object.SearchInput}&SearchType=${object.SearchType || 0}&candidacyType=${object.candidacyType || 0}&listType=${object.listType || 0}`);
    },
    GetCandidateFromLegalAmendments(candidateId) {
        return axios.get(`/Api/Admin/LegalAmendments/Get/${candidateId}`);
    },
    GetSenateCandidateFromLegalAmendments(candidateId) {
        return axios.get(`/Api/Admin/LegalAmendments/SenateCandidateGet/${candidateId}`);
    },

    ExportLegalAmendmentsCandidateListToExcel() {
        return axios.get(`/Api/Admin/LegalAmendments/Export/Candidate/Excel`);
    },

    ExportLegalAmendmentsSenateCandidateListToExcel() {
        return axios.get(`/Api/Admin/LegalAmendments/Export/SenateCandidate/Excel`);
    },


    ExporLegalAmendmentEntityCandidateListToExcel() {
        return axios.get(`/Api/Admin/LegalAmendments/Export/EntityCandidates/Excel`);
    },


    MoveToLegalAmendments(object) {
        return axios.post(`/Api/Admin/LegalAmendments/Candidate/Edit`, object);
    },
    SenateMoveToLegalAmendments(object) {
        return axios.post(`/Api/Admin/LegalAmendments/SenateCandidate/Edit`, object);
    },
    MoveEntityCandidateToLegalAmendments(object) {
        return axios.post(`/Api/Admin/LegalAmendments/Entity/Edit`, object);
    },
    GetPresidentialCandidatesFromLegalAmendments(object) {
        return axios.get(`/Api/Admin/LegalAmendments/Get/PresidentialCandidates?pageNo=${object.pageNo}&pageSize=${object.pageSize}&SearchInput=${object.SearchInput}&SearchType=${object.SearchType || 0}&candidacyType=${object.candidacyType || 0}&listType=${object.listType || 0}`);
    },

    GetPresidentialCandidatFromLegalAmendments(candidateId) {
        return axios.get(`/Api/Admin/LegalAmendments/Get/PresidentialCandidat/${candidateId}`);
    },
    MovePresidentialCandidateToLegalAmendments(object) {
        return axios.post(`/Api/Admin/LegalAmendments/Edit/PresidentialCandidate`, object);
    },

    //************************ Chairs **************************
    GetChairs(pageNo, pageSize) {
        return axios.get(`/Api/Admin/Chairs/Get?pageno=${pageNo}&pagesize=${pageSize}`);
    },

    GetChairsDetails(pageNo, pageSize) {
        return axios.get(`/Api/Admin/Chairs/Get/Details?pageno=${pageNo}&pagesize=${pageSize}`);
    },

    deleteChairs(id) {
        return axios.post(`/Api/Admin/Chairs/${id}/delete`);
    },

    deleteChairsDetails(id) {
        return axios.post(`/Api/Admin/Chairs/${id}/Details/Delete`);
    },

    AddChairs(form) {
        return axios.post(`/Api/Admin/Chairs/Add`, form);
    },

    AddChairsDetails(form) {
        return axios.post(`/Api/Admin/Chairs/Add/Details`, form);
    },

    //************************ Endorsements **************************
    GetEndorsements(CandidateId, pageNo, pageSize) {
        return axios.get(`/Api/Admin/Endorsements?candidateId=${CandidateId}&pageNo=${pageNo}&pageSize=${pageSize}`);
    },
    GetEndorsementsByNid(Nid) {

        return axios.get(`/Api/Admin/Endorsements/Get?nationalId=${Nid}`);
    },
    AddEndorsement(obj) {
        return axios.post(`/Api/Admin/Endorsements`, obj);
    },
    AddNewEndorsement(obj) {
        return axios.post(`/Api/Admin/Endorsements/Add`, obj);
    },
    PrintEndoresmentList(pcid) {
        return axios.get(`/Api/Admin/Endorsements/PrintEndoresmentList/${pcid}`);
    },
    //************************ Statistics **************************
    GetStatistics() {
        return axios.get(`/Api/Admin/Statistics`);
    },
    UpdateStatistics() {
        return axios.put(`/Api/Admin/Statistics`);
    },
    //************************ Report **************************

    GetMainRaceReport(object) {
        return axios.post(`/api/Admin/Report/MainRace`, object);
    },
    GetOfficeRaceReport(object) {
        return axios.post(`/api/Admin/Report/OfficeRace`, object);
    },

    GetSubRaceReport(object) {
        return axios.post(`/api/Admin/Report/SubRaceReport`, object);
    },
    ExportMainRaceReportToExcel(object) {
        return axios.post(`/api/Admin/Report/Export/MainRace/Excel`, object);
    },

    ExportOfficeRaceReportToExcel(object) {
        return axios.post(`/api/Admin/Report/Export/OfficeRace/Excel`, object);
    },

    ExportSubRaceReportToExcel(object) {
        return axios.post(`/api/Admin/Report/Export/SubRaceReport/Excel`, object);
    },

    ExportMainRaceReportToPDF(object) {
        return axios.post(`/api/Admin/Report/Export/MainRace/PDF`, object);
    },
    ExportSubDistrictReportToPDF(object) {
        return axios.post(`/api/Admin/Report/Export/SubDistrict/PDF`, object);
    },
    ExportOfficeReportToPDF(object) {
        return axios.post(`/api/Admin/Report/Export/Office/PDF`, object);
    },
    //************************ ReportSenate **************************

    GetMainRaceReportSenate(object) {
        console.log(object);
        return axios.post(`/api/Admin/ReportSenate/MainRace`, object);
    },
    GetOfficeRaceReportSenate(object) {
        return axios.post(`/api/Admin/ReportSenate/OfficeRace`, object);
    },

    GetSubRaceReportSenate(object) {
        return axios.post(`/api/Admin/ReportSenate/SubRaceReport`, object);
    },
    ExportMainRaceReportToExcelSenate(object) {
        return axios.post(`/api/Admin/ReportSenate/Export/MainRace/Excel`, object);
    },

    ExportOfficeRaceReportToExcelSenate(object) {
        return axios.post(`/api/Admin/ReportSenate/Export/OfficeRace/Excel`, object);
    },

    ExportSubRaceReportToExcelSenate(object) {
        return axios.post(`/api/Admin/ReportSenate/Export/SubRaceReport/Excel`, object);
    },

    ExportMainRaceReportToPDFSenate(object) {
        return axios.post(`/api/Admin/ReportSenate/Export/MainRace/PDF`, object);
    },
    ExportSubDistrictReportToPDFSenate(object) {
        return axios.post(`/api/Admin/ReportSenate/Export/SubDistrict/PDF`, object);
    },
    ExportOfficeReportToPDFSenate(object) {
        return axios.post(`/api/Admin/ReportSenate/Export/Office/PDF`, object);
    },


}