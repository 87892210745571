export const routes = [
    {
        path: '/',
        component: () => import('../components/Home/Home.vue'),
        meta: { requiresAuth: true }
    },
    {
        path: '/Profiles',
        component: () => import('../components/Profiles/Profiles.vue'),
        meta: { requiresAuth: true, requiredUserType: [1] }
    },
    {
        path: '/Users',
        component: () => import('../components/Users/Users.vue'),
        meta: { requiresAuth: true, requiredUserType: [1] }
    },
    {
        path: '/AddUsers',
        component: () => import('../components/Users/AddUsers/AddUsers.vue'),
        meta: { requiresAuth: true, requiredUserType: [1] }
    },
    {
        path: '/EditUsersProfile',
        component: () => import('../components/Users/EditUsersProfile/EditUsersProfile.vue'),
        meta: { requiresAuth: true, requiredUserType: [1, 2, 3] }
    },
    {
        path: '/ChangePassword',
        component: () => import('../components/Users/ChangePassword/ChangePassword.vue'),
        meta: { requiresAuth: true, requiredUserType: [1, 2, 3] }
    },
    {
        path: '/Administration/Constituency',
        component: () => import('../components/Administration/Constituency/Constituency.vue'),
        meta: { requiresAuth: true, requiredUserType: [1] }
    },
    {
        path: '/Administration/Subconstituency',
        component: () => import('../components/Administration/ConstituencyDetails/ConstituencyDetails.vue'),
        meta: { requiresAuth: true, requiredUserType: [1] }
    },
    {
        path: '/EntityCandidates',
        component: () => import('../components/EntityCandidates/EntityCandidates.vue'),
        meta: { requiresAuth: true, requiredUserType: [1, 2, 3] }
    },
    {
        path: '/EntityCandidatesFirstList',
        component: () => import('../components/EntityCandidatesFirstList/EntityCandidatesFirstList.vue'),
        meta: { requiresAuth: true, requiredUserType: [1, 2, 3] }
    },
    {
        path: '/EntityCandidatesFinalList',
        component: () => import('../components/EntityCandidatesFinalList/EntityCandidatesFinalList.vue'),
        meta: { requiresAuth: true, requiredUserType: [1, 2] }
    },
    {
        path: '/EntityLegalAmendments',
        component: () => import('../components/EntityLegalAmendments/EntityLegalAmendments.vue'),
        meta: { requiresAuth: true, requiredUserType: [1, 2] }
    },
    {
        path: '/Candidates',
        component: () => import('../components/Candidates/Candidates.vue'),
        meta: { requiresAuth: true, requiredUserType: [1, 2, 3] }
    },
    {
        path: '/CandidatesFirstList',
        component: () => import('../components/CandidatesFirstList/CandidatesFirstList.vue'),
        meta: { requiresAuth: true, requiredUserType: [1, 2, 3] }
    },
    {
        path: '/CandidatesFinalList',
        component: () => import('../components/CandidatesFinalList/CandidatesFinalList.vue'),
        meta: { requiresAuth: true, requiredUserType: [1, 2] }
    },
    {
        path: '/Entites',
        component: () => import('../components/Entities/Entities.vue'),
        meta: { requiresAuth: true, requiredUserType: [1, 2, 3] }
    },
    // GeneralReport.vue inside Reports folder
    {
        path: '/Reports',
        component: () => import('../components/Reports/GeneralReport.vue'),
        meta: { requiresAuth: true, requiredUserType: [1] }
    },
    //GeneralAttorneyReport
    {
        path: '/GeneralAttorneyReport',
        component: () => import('../components/Reports/GeneralAttorneyReport.vue'),
        meta: { requiresAuth: true, requiredUserType: [1] }
    },
    {
        path: '/LegalAmendments',
        component: () => import('../components/LegalAmendments/LegalAmendments.vue'),
        meta: { requiresAuth: true, requiredUserType: [1, 2] }
    },
    {
        path: '/Supporters',
        component: () => import('../components/Supporters/Supporters.vue'),
        meta: { requiresAuth: true, requiredUserType: [1] }
    },
    {
        path: '/Security/Login',
        component: () => import('../components/Login/Login.vue'),
        meta: { requiresAuth: false }
    },
    {
        path: '*',
        component: () => import('../components/Errors/NotFound.vue')
    }
];
